.logo{
  background-image: url('./img/logo.png');
  width: 10rem;
  height: 8rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
  margin-bottom: 2rem;
}
.main_back{
  background-image: url('./img/back.png');
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pay_list{
  width:43%;
  height: 7rem;
  border: 1px solid rgba(224, 159, 19, 0.705);
  border-radius: .5rem;
  background-color: rgba(238, 238, 238, 0.911);
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  margin: .5rem;
}
.money_list{
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.pay_list_sel{
  border: 2px solid rgb(197, 204, 197);
  background-color: rgba(0, 128, 0, 0.712);

}
.money_txt{
  color: rgb(235, 157, 13);
  font-size: 18px;
  font-weight: bold;
  line-height: 2rem;
}
.tehui{
  background-image: url('./img/tehui.png');
  background-repeat: no-repeat;
  background-position: 25px -1px;
  box-shadow: 5px 5px 5px;
}
.payok{
  width: 50%;
  height: 3rem;
  border: 1px solid white;
  margin: 1rem auto;
  border-radius: .5rem;
  background-color: green;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
}
.mch_txt{
  color: rgb(196, 196, 196);
  margin: 1.5rem 1.5rem;
  font-size: 16px;  
}
.usequan{
  margin: 1rem 1.5rem;
  height: 5rem;
  color: rgb(196, 196, 196);
}
.usequan *{
  float: left;
}
.input_txt{
  width: 65%;
  margin: .5rem 0rem;
  height: 2rem;
  border-radius: .3rem;  
}
.quan_btn{
  width: 20%;
  border: 1px solid green;
  background-color: green;
  display: flex;
  margin: .5rem .3rem;
  border-radius: .3rem; 
  height: 2rem;
  align-items: center;
  justify-content: center;
}